import { lazy } from 'react';
const configRouter = [
    {
        path:"/",
        name:"首页",
        exact:true,
        component:lazy(()=> import('./../component/home'))
    },
    {
        path:"/underlyingList",
        name:"标的列表",
        component:lazy(()=> import('./../component/underlyingList'))
    },
    {
        path:"/noticeList",
        name:"通告列表",
        component:lazy(()=> import('./../component/noticeList'))
    },
    {
        path:"/noticeDetails",
        name:"通告详情",
        component:lazy(()=> import('./../component/noticeList/details'))
    },
    {
        path:"/underlyingDetails",
        name:"房源详情",
        component:lazy(()=> import('./../component/underlyingList/details'))
    },
    {
        path:"/login",
        name:"登录",
        component:lazy(()=> import('./../component/login'))
    },
    {
        path:"/registered",
        name:"注册",
        component:lazy(()=> import('./../component/login/registered'))
    },
    {
        path:"/loginOk",
        name:"注册成功",
        component:lazy(()=> import('./../component/login/loginOk'))
    },
    {
        path:"/FindPassword",
        name:"找回密码",
        component:lazy(()=> import('./../component/login/findPassword'))
    },
    {
        path:"/notCDHome",
        name:"没有数据",
        component:lazy(()=> import('./../component/home/notCDHome'))
    },
    {
        path:"/changePassword",
        name:"修改密码",
        component:lazy(()=> import('./../component/login/changePassword'))
    },
    {
        path:"/mapLookRoom",
        name:"地图找房",
        component:lazy(()=> import('./../component/mapLookRoom'))
    },
    {
        path:"/addRecord",
        name:"添加看房记录",
        component:lazy(()=> import('./../component/underlyingList/addrecord'))
    },
    {
        path:"/banner_link",
        name:"房屋详情",
        component:lazy(()=> import('./../component/bannerLink'))
    },
    {
        path:"/PersonalCenter",
        name:"个人中心",
        component:lazy(()=> import('./../component/personalCenter')),
        children:[
            {
                path:"/PersonalCenter",
                name:"个人中心",
                exact:true,
                component:lazy(()=> import('./../component/personalCenter/user')),
            },
            {
                path:"/PersonalCenter/editPassword",
                name:"修改密码",
                exact:true,
                component:lazy(()=> import('./../component/personalCenter/changePassword')),
            }
            // {
            //     path:"/PersonalCenter/history",
            //     name:"浏览记录",
            //     exact:true,
            //     component:lazy(()=> import('./../component/personalCenter/history')),
            // }
        ]
    }
]

export default configRouter
