import React, { Suspense } from "react";
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import configRouter from "./config";
import LoadingPage from "./../component/loading";

const router = (config: any) => {
    if (!Array.isArray(config)) {
        return null;
    }
    return <Switch>
        {config.map((route: any, index: number) => {
            if (route.redirect) {
                return (
                    <Redirect
                        key={route.path || index}
                        exact={route.exact}
                        strict={route.strict}
                        from={route.path}
                        to={route.redirect}
                    />
                );
            }

            return (
                <Route
                    key={route.path || index}
                    path={route.path}
                    exact={route.exact}
                    render={() => {
                        const renderChildRoutes = router(route.children);
                        if (route.component) {
                            return (
                                <Suspense fallback={<LoadingPage />}>
                                    <route.component route={route}>
                                        {renderChildRoutes}
                                    </route.component>
                                </Suspense>

                            );
                        }
                        return renderChildRoutes;
                    }}
                />
            );
        })}
    </Switch>
}

const AppRouter = () => {
    return <Router>{router(configRouter)}</Router>
}
export default AppRouter
