import './polyfill';
import 'core-js/es';
import 'mutation-observer';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppRouter from "./router/index"
import * as serviceWorker from './serviceWorker';
import zhCN from 'antd/es/locale/zh_CN';
import 'antd/dist/antd.css';
import { ConfigProvider } from "antd";
ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <React.StrictMode>
      <AppRouter />
    </React.StrictMode>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
